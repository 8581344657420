var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wscn-http404-container"
  }, [_c("div", {
    staticClass: "wscn-http404"
  }, [_vm._m(0), _c("div", {
    staticClass: "bullshit"
  }, [_c("div", {
    staticClass: "bullshit__oops"
  }, [_vm._v(" 404错误! ")]), _c("div", {
    staticClass: "bullshit__headline"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c("div", {
    staticClass: "bullshit__info"
  }, [_vm._v(" 对不起，您正在寻找的页面不存在。尝试检查URL的错误，然后按浏览器上的刷新按钮或尝试在我们的应用程序中找到其他内容。 ")]), _c("router-link", {
    staticClass: "bullshit__return-home",
    attrs: {
      to: "/"
    }
  }, [_vm._v(" 返回首页 ")])], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pic-404"
  }, [_c("img", {
    staticClass: "pic-404__parent",
    attrs: {
      src: require("@/assets/404_images/404.png"),
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child left",
    attrs: {
      src: require("@/assets/404_images/404_cloud.png"),
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child mid",
    attrs: {
      src: require("@/assets/404_images/404_cloud.png"),
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child right",
    attrs: {
      src: require("@/assets/404_images/404_cloud.png"),
      alt: "404"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };